<template>
  <v-card>
    <v-card-title>
      Kontaktanfragen
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchen" single-line hide-details clearable />
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete label="Zugeteilter Benutzer" v-model="assign" :loading="loadingUsers" :items="users" item-text="displayName" item-value="path" clearable>
            <template v-slot:item="data">
              <v-list-item-avatar color="primary">
                <span class="white--text">{{ data.item.shortName }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.displayName"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete label="Status" v-model="status" :items="statusItems" item-value="value" cache-items clearable>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.text"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete label="Kontaktgruppe" v-model="group" :items="groupItems" cache-items clearable>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.text"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table :headers="headers" :items="contacts" :search="search" :loading="loading" item-key="id" virtual-rows sort-by="name">
      <template v-slot:item.website="{ item }">{{ item.website | parseUrl }}</template>
      <template v-slot:item.assign="{ item }">
        <v-tooltip bottom open-delay="500" v-if="item.assign">
          <template v-slot:activator="{ on }">
            <v-avatar color="primary" size="36" v-on="on">
              <span class="white--text">{{ item.assign.shortName }}</span>
            </v-avatar>
          </template>
          <span>{{ item.assign.displayName }}</span>
        </v-tooltip>
        <v-btn text small v-else @click="claimContact(item)">claim</v-btn>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getStatusColor(item.status)" dark>{{ getStatusText(item.status) }}</v-chip>
      </template>
      <template v-slot:item.group="{ item }">{{ getGroupText(item.group) }}</template>
      <template v-slot:item.action="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="updateContact(item)">
              <v-list-item-title>Bearbeiten</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteContact(item)">
              <v-list-item-title>Löschen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <!-- <v-btn fab bottom left dark absolute x-large color="pink" @click="createContact">
      <v-icon>mdi-plus</v-icon>
    </v-btn>-->
    <v-speed-dial v-model="addButton" bottom left absolute class="create-btn" direction="top" transition="slide-y-reverse-transition">
      <template v-slot:activator>
        <v-btn v-model="addButton" color="pink" dark fab x-large>
          <v-icon v-if="addButton">mdi-close</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="green" @click="importContacts">
        <v-icon>mdi-application-import</v-icon>
      </v-btn>
      <v-btn fab dark small color="indigo" @click="createContact">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>
    <Form />
    <Import />
    <Delete />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import url from 'url';
import urlFormat from 'format-url';
import _ from 'lodash';
import { request } from '@/helpers/status';
import Groups from '@/helpers/groups';

export default {
  components: {
    Form: () => import(/* webpackChunkName: "request" */ '@/components/scoped/request/Form.vue'),
    Import: () => import(/* webpackChunkName: "request" */ '@/components/scoped/request/Import.vue'),
    Delete: () => import(/* webpackChunkName: "request" */ '@/components/scoped/request/Delete.vue'),
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    search: '',
    assign: '',
    status: '',
    group: '',
    statusItems: request,
    groupItems: Groups,
    addButton: false,
  }),

  computed: {
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Adresse',
          value: 'address',
        },
        {
          text: 'Ort',
          value: 'city',
        },
        {
          text: 'PLZ',
          value: 'zip',
        },
        {
          text: 'E-Mail',
          value: 'email',
        },
        {
          text: 'Webseite',
          value: 'website',
        },
        {
          text: 'Telefon',
          value: 'phone',
        },
        {
          text: 'Verantwortlicher',
          value: 'assign',
          align: 'center',
          filter: (value) => {
            if (!this.assign) {
              return true;
            }
            if (!value) {
              return false;
            }
            return value.path === this.assign;
          },
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          filter: (value) => {
            if (!this.status) return true;
            return value === this.status;
          },
        },
        {
          text: 'Kontaktgruppe',
          value: 'group',
          filter: (value) => {
            if (!this.group) return true;
            return value === this.group;
          },
        },
        {
          value: 'action',
          align: 'right',
          filter: false,
          sortable: false,
        },
      ];
    },
    ...mapGetters({
      auth: 'auth/get',
      users: 'user/all',
      loadingUsers: 'user/loading',
    }),
  },

  filters: {
    parseUrl(value) {
      if (value) {
        const urlFormated = urlFormat(value);
        const urlParts = url.parse(urlFormated);
        return urlParts.host;
      }
      return null;
    },
  },

  methods: {
    createContact() {
      this.$store.commit('request/dialog/toggleForm', true);
    },
    importContacts() {
      this.$store.commit('request/dialog/toggleImport', true);
    },
    updateContact(contact) {
      this.$store.dispatch('request/load', contact.id).then(() => {
        this.$store.commit('request/sync');
      });
      this.$store.commit('request/dialog/toggleForm', true);
    },
    deleteContact(contact) {
      this.$store.dispatch('request/load', contact.id);
      this.$store.commit('request/dialog/toggleDelete', true);
    },
    claimContact(contact) {
      if (this.auth) {
        this.$store.dispatch('request/claim', { id: contact.id, assign: this.auth.path });
      }
    },
    getStatusColor(status) {
      if (!status) return null;
      const item = _.find(this.statusItems, ['value', status]);
      return item.color;
    },
    getStatusText(status) {
      if (!status) return null;
      const item = _.find(this.statusItems, ['value', status]);
      return item.text;
    },
    getGroupText(group) {
      if (!group) return null;
      const item = _.find(this.groupItems, ['value', group]);
      return item.text;
    },
  },

  created() {
    this.$store.dispatch('user/loadAllActive');
  },

  destroyed() {
    this.$store.commit('user/resetAll');
  },
};
</script>

<style lang="scss" scoped>
.create-btn {
  bottom: -36px;
}
</style>
