<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">CSV importieren</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2" :editable="step > 2">Felder zuweisen</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3" :editable="step > 3">Kategorien zuweisen</v-stepper-step>
      </v-stepper-header>
      <base-alert v-if="error && dialog">{{ error.message }}</base-alert>
      <v-stepper-items>
        <v-stepper-content step="1" class="pl-0 pr-0 pb-0">
          <v-card flat>
            <v-card-text>
              <div :class="['dropzone-area', dragging ? 'dropzone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false" drag-over="handleDragOver">
                <span class="headline">Drop files here or click to upload.</span>
                <input type="file" @change="upload" accept=".csv" />
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click.stop="onClose">Abbrechen</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2" class="pl-0 pr-0 pb-0">
          <v-card flat>
            <v-card-text>
              <v-select label="Status" :items="csvFields" v-model="fieldMapping.status" />
              <v-select label="Kontaktgruppe" :items="csvFields" v-model="fieldMapping.group" />
              <v-row no-gutters>
                <v-col cols="12" md>
                  <v-select label="Name" :items="csvFields" v-model="fieldMapping.name" />
                  <v-select label="Adresse" :items="csvFields" v-model="fieldMapping.address" />
                  <v-select label="Adresszusatz" :items="csvFields" v-model="fieldMapping.additional" />
                  <v-row no-gutters>
                    <v-col cols="4" class="pr-3">
                      <v-select label="PLZ" :items="csvFields" v-model="fieldMapping.zip" />
                    </v-col>
                    <v-col cols="8" class="pl-3">
                      <v-select label="Ort" :items="csvFields" v-model="fieldMapping.city" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md offset-md="1">
                  <v-select label="E-Mail" :items="csvFields" v-model="fieldMapping.email" />
                  <v-select label="Webseite" :items="csvFields" v-model="fieldMapping.website" />
                  <v-select label="Telefon" :items="csvFields" v-model="fieldMapping.phone" />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md>
                  <v-select label="Kategorien" :items="csvFields" v-model="fieldMapping.category" />
                </v-col>
                <v-col cols="12" md offset-md="1">
                  <v-select label="Unterkategorien" :items="csvFields" v-model="fieldMapping.subcategory" />
                </v-col>
              </v-row>
              <v-select label="Detail" :items="csvFields" v-model="fieldMapping.description" />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click.stop="onClose">Abbrechen</v-btn>
              <!-- <v-btn color="primary" @click="validate">Validate</v-btn> -->
              <v-btn color="primary" @click="step = 3">Weiter</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3" class="pl-0 pr-0 pb-0">
          <v-card flat>
            <v-card-text v-if="categories">
              <v-autocomplete
                v-for="(categoryData, category) in categoryMapping"
                :key="`category-${category}`"
                :label="category"
                :loading="loadingCategories"
                :items="categories"
                item-text="name.de"
                item-value="path"
                clearable
              />
              <!-- <v-select v-for="(categoryData, category) in categories" :key="`category-${category}`" label="category" :items="csvFields" />
              <div
                v-for="(categoryData, category) in categories"
                :key="`category-${category}`"
              >{{ category }}</div>-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click.stop="onClose">Abbrechen</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import csv from 'csvtojson';
import { groupBy } from '@/helpers/groupBy';

export default {
  data: () => ({
    step: 0,
    dragging: false,
    csvData: [],
    csvFields: [],
    fieldMapping: {
      group: '',
      name: '',
      address: '',
      additional: '',
      zip: '',
      city: '',
      email: '',
      website: '',
      phone: '',
      description: '',
      category: '',
      subcategory: '',
      status: '',
    },
  }),

  watch: {
    dialog(value, oldValue) {
      // on open
      if (value && !oldValue) {
        this.$store.dispatch('category/loadAll');
      }
    },
    csvData(value) {
      if (value.length > 0) {
        this.$data.csvFields = Object.keys(value[0]);
      }
    },
  },

  computed: {
    ...mapGetters({
      dialog: 'request/dialog/import',
      categories: 'category/all',
      loadingCategories: 'category/loading',
      loading: 'loading/get',
      error: 'error/get',
    }),
    categoryMapping() {
      if (this.$data.csvData.length <= 0 || this.$data.fieldMapping.category === '') {
        return [];
      }

      const groupByCategory = groupBy(this.$data.fieldMapping.category);
      // console.log(groupByCategory(this.$data.csvData));
      // console.log(this.$data.csvData);
      return groupByCategory(this.$data.csvData);
    },
  },

  methods: {
    onClose() {
      this.$store.commit('request/dialog/toggleImport', false);
    },
    upload(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (files.length !== 1) {
        this.$store.commit('error/set', { message: 'Es kann nur eine Datei hochgeladen werden.' });
        return;
      }

      const file = event.target.files[0];
      if (!file.name.endsWith('.csv')) {
        this.$store.commit('error/set', { message: 'Es kann nur eine CSV-Datei hochgeladen werden.' });
        return;
      }

      const fileReader = new FileReader();
      fileReader.onloadend = (fr) => {
        csv().fromString(fr.currentTarget.result).then((jsonObj) => {
          this.$data.csvData = jsonObj;
          this.$data.step = 2;
        });
      };
      fileReader.readAsText(file);
    },
    validate() {
      // console.log(this.$data.fieldMapping);
    },
  },

  destroyed() {
    if (this.dialog) {
      this.onClose();
    }
  },
};
</script>

<style lang="scss" scoped>
.dropzone-area {
  height: 200px;
  position: relative;
  border: 2px dashed #cbcbcb;
  background-color: #fff;
  border-radius: 5px;

  &.dropzone-over {
    border-style: solid;
    border-color: #f00;
  }

  span {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  input {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
