<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <v-card>
      <v-form @submit.prevent="onSubmit">
        <v-toolbar dark>
          <v-toolbar-title>Kontakt {{ contact ? "bearbeiten" : "erstellen" }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <base-alert v-if="error && dialog">{{ error.message }}</base-alert>
          <v-row no-gutters>
            <v-col cols="12" md>
              <v-autocomplete label="Status" v-model="status" :items="statusItems" item-value="value" cache-items>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.text"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-dialog v-model="statusAlert" persistent max-width="600">
                <v-card>
                  <v-card-title class="headline">Status ändern?</v-card-title>
                  <v-card-text>
                    <p>{{ statusAlertMessage }}</p>
                    <p>Sind Sie sicher, dass Sie den Status ändern möchten?</p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="cancleStatusAlert">Abbrechen</v-btn>
                    <v-btn color="error" @click="changeStatus">Status ändern</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" md offset-md="1">
              <v-menu ref="reminderMenu" v-model="reminderMenu" :close-on-content-click="false" :return-value.sync="reminderDate" offset-y min-width="290px" v-if="status === 'REQUESTED'">
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="reminderFormatted" label="Reminder wird gesendet am" readonly @blur="reminderDate = parseDate(reminderFormatted)" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="reminderDate" no-title @input="$refs.reminderMenu.save(reminderDate)" :min="reminderMinDate"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md>
              <v-autocomplete label="Verantwortlicher Benutzer" v-model="assign" :loading="loadingUsers" :items="users" item-text="displayName" item-value="path" clearable>
                <template v-slot:item="data">
                  <v-list-item-avatar color="primary">
                    <span class="white--text">{{ data.item.displayName | shortName }}</span>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.displayName"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md offset-md="1">
              <v-autocomplete label="Kontaktgruppe" v-model="group" :items="groupItems" cache-items clearable>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.text"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md>
              <v-text-field label="Name" v-model="name" v-validate="'required'" :error-messages="errors.collect('name')" data-vv-name="name" required />
              <v-text-field label="Adresse" v-model="address" />
              <v-text-field label="Adresszusatz" v-model="additional" />
              <v-row no-gutters>
                <v-col cols="4" class="pr-3">
                  <v-text-field label="PLZ" v-model="zip" />
                </v-col>
                <v-col cols="8" class="pl-3">
                  <v-text-field label="Ort" v-model="city" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md offset-md="1">
              <v-text-field label="E-Mail" type="email" v-model="email" v-validate="'required|email'" :error-messages="errors.collect('email')" data-vv-name="email" required />
              <v-text-field label="Webseite" type="url" v-model="website" />
              <v-text-field label="Telefon" v-model="phone" />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md>
              <v-autocomplete label="Kategorien" v-model="category" :loading="loadingCategories" :items="categories" item-text="name.de" item-value="path" cache-items clearable />
            </v-col>
            <v-col cols="12" md offset-md="1">
              <v-autocomplete label="Unterkategorien" v-model="subcategory" :loading="loadingSubcategories" :items="subcategories" item-text="name.de" item-value="path" clearable />
            </v-col>
          </v-row>
          <v-textarea label="Detail" v-model="description" hint="Wird nur intern angezeigt" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="onClose" :disabled="loading">Abbrechen</v-btn>
          <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">{{ contact ? "Speichern" : "Hinzufügen" }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import dayjs from 'dayjs';
import { request } from '@/helpers/status';
import Groups from '@/helpers/groups';

const { mapFields } = createHelpers({
  getterType: 'request/field',
  mutationType: 'request/updateField',
});

export default {
  $_veeValidate: {
    validator: 'new',
  },

  data: () => ({
    statusItems: request,
    groupItems: Groups,
    reminderMenu: false,
    reminderMinDate: dayjs().format('YYYY-MM-DD'),
    reminderFormatted: '',
    statusAlertMessage: '',
    statusAlertValue: null,
    ignoreStatusAlertChange: false,
    ignoreCategoryChange: false,
  }),

  computed: {
    ...mapGetters({
      dialog: 'request/dialog/form',
      statusAlert: 'request/dialog/status',
      contact: 'request/get',
      users: 'user/all',
      categories: 'category/all',
      subcategories: 'category/subcategory/all',
      loadingUsers: 'user/loading',
      loadingCategories: 'category/loading',
      loadingSubcategories: 'category/subcategory/loading',
      loading: 'loading/get',
      error: 'error/get',
    }),
    ...mapFields(['group', 'name', 'address', 'additional', 'zip', 'city', 'email', 'website', 'phone', 'description', 'category', 'subcategory', 'status', 'reminderDate', 'assign']),
  },

  watch: {
    dialog(value, oldValue) {
      // on open
      if (value && !oldValue) {
        this.$store.dispatch('category/loadAll');
      }

      // on close
      if (oldValue && !value) {
        this.resetForm();
      }
      this.$validator.reset();
    },
    contact(value) {
      if (value) {
        this.ignoreStatusAlertChange = true;
        this.ignoreCategoryChange = true;
      }
    },
    status(value, oldValue) {
      if (this.ignoreStatusAlertChange) {
        this.ignoreStatusAlertChange = false;
        return;
      }

      if (value === 'READY_TO_REQUEST' || value === 'REQUESTED' || value === 'REMINDER' || value === 'REQUESTED_REMINDER') {
        switch (value) {
          case 'READY_TO_REQUEST':
            this.statusAlertMessage = 'Wenn Sie auf diesen Status ändern, wird ein E-Mail versendet.';
            break;
          case 'REQUESTED':
            this.statusAlertMessage = 'Dieser Status zeigt an, dass ein E-Mail gesendet wurde.';
            break;
          case 'REMINDER':
            this.statusAlertMessage = 'Es wird ein Reminder E-Mail gesendet.';
            break;
          case 'REQUESTED_REMINDER':
            this.statusAlertMessage = 'Dieser Status zeigt an, dass ein Reminder E-Mail gesendet wurde.';
            break;
          default:
            this.statusAlertMessage = '';
        }
        this.statusAlertValue = oldValue;
        this.$store.commit('request/dialog/toggleStatus', true);
      }
    },
    category(value) {
      if (this.ignoreCategoryChange) {
        this.ignoreCategoryChange = false;
      } else {
        this.subcategory = '';
        this.$store.commit('category/subcategory/resetAll');
      }

      if (value) {
        this.$store.dispatch('category/subcategory/loadAllByCategoryPath', value);
      }
    },
    reminderDate() {
      this.reminderFormatted = this.formatDate(this.reminderDate);
    },
  },

  filters: {
    shortName(value) {
      const initials = value.match(/\b\w/g) || [];
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    },
  },

  methods: {
    onClose() {
      this.$store.commit('request/dialog/toggleForm', false);
    },
    onSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          const data = {
            group: this.group,
            name: this.name,
            address: this.address,
            additional: this.additional,
            zip: this.zip,
            city: this.city,
            email: this.email,
            website: this.website,
            phone: this.phone,
            description: this.description,
            category: this.category,
            subcategory: this.subcategory,
            status: this.status,
            reminderDate: this.reminderDate,
            assign: this.assign,
          };

          if (this.contact) {
            this.$store.dispatch('request/update', data);
          } else {
            this.$store.dispatch('request/create', data);
          }
        }
      });
    },
    resetForm() {
      this.$store.commit('request/reset');
      this.$store.commit('category/resetAll');
      this.$store.commit('category/subcategory/resetAll');
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split('.');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    cancleStatusAlert() {
      this.status = this.statusAlertValue;
      this.ignoreStatusAlertChange = true;
      this.$store.commit('request/dialog/toggleStatus', false);
    },
    changeStatus() {
      this.$store.commit('request/dialog/toggleStatus', false);
    },
  },

  destroyed() {
    if (this.dialog) {
      this.onClose();
      this.resetForm();
    }
  },
};
</script>
