export default [
  {
    text: 'Orte',
    description: 'Wanderwege, Spielplätze, Klettertürme, ...etc',
    value: 'LOCATIONS',
  },
  {
    text: 'Kleinveranstalter',
    description: 'Vereinfachtes Programm',
    value: 'SMALL',
  },
  {
    text: 'Grossveranstalter',
    description: 'Mittel und Grossveranstalter, die mehrere Programme anbieten',
    value: 'ORGANIZER',
  },
  {
    text: 'Saisonale Veranstalter',
    description: 'Räbeliechtli, Samichlaus und Tannenbaum schneiden',
    value: 'SEASONAL',
  },
];
