<template>
  <Requests :data="contacts" :loading="loading" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    Requests: () => import(/* webpackChunkName: "request" */ '@/components/scoped/request/Table.vue'),
  },

  computed: {
    ...mapGetters({
      loading: 'request/loading',
      contacts: 'request/all',
    }),
  },

  methods: {
    loadData() {
      this.$store.dispatch('request/loadAll');
    },
    destroyData() {
      this.$store.commit('request/resetAll');
    },
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.destroyData();
  },
};
</script>
