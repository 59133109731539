<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card v-if="contact">
      <base-alert v-if="error && dialog">{{ error.message }}</base-alert>
      <v-card-title class="headline">Kontakt löschen?</v-card-title>
      <v-card-text>
        <p>
          Möchten Sie den Kontakt
          <strong>«{{ contact.name }}»</strong> wirklich löschen?
        </p>
        <p>Der Vorgang kann nicht rückgängig gemacht werden.</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.stop="onClose" :disabled="loading">Abbrechen</v-btn>
        <v-btn color="error" @click.stop="onDelete" :loading="loading" :disabled="loading">Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      dialog: 'request/dialog/delete',
      contact: 'request/get',
      loading: 'loading/get',
      error: 'error/get',
    }),
  },

  watch: {
    dialog(value, oldValue) {
      if (oldValue && !value) {
        this.resetData();
      }
    },
  },

  methods: {
    onClose() {
      this.$store.commit('request/dialog/toggleDelete', false);
    },
    onDelete() {
      this.$store.dispatch('request/delete');
    },
    resetData() {
      this.$store.commit('request/reset');
    },
  },

  destroyed() {
    if (this.dialog) {
      this.onClose();
      this.resetData();
    }
  },
};
</script>
